import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {discoverBackground} from "./assets.tsx";
import './style.css';


export function JoinUs(){
    return(
        <>
        <Box className="bannerBackgroundJoinus">
        <Container>
            <Typography className="bannerTitleJoinus">Join Us in Shaping the Future</Typography>
<Typography className="bannerBodyJoinus">As the demand for lithium-ion batteries continues to grow, so too does the need for innovative solutions that are both efficient and sustainable. At AI4.0S, we invite you to join us on this journey as we work towards a brighter, more sustainable future for generations to come. Together, we can shape the future of energy storage production and recycling and make a lasting impact on the world.</Typography>
</Container>
        </Box>
        </>
    )
}