import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {discoverBackground, aboutUsSideImage} from "./assets.tsx";
import './style.css';


export function AboutUs(){
    return(
        <>
        <Box className="bannerBackgroundAboutus">
        <Container>
            <Grid container direction="row" spacing={4}>
                <Grid item xs={6}>
                <Typography className="bannerTitleAboutus">About Us</Typography>
            <Typography className="bannerBodyAboutus">At AI4.0S, we are at the forefront of innovation in the realm of clean and renewable energy manufacturing and sustainable recycling. Established in 2023, we have consistently strived to pioneer cutting-edge technologies and sustainable practices to address the ever-growing demand for energy storage solutions while minimizing environmental impact. We provide solutions from feasibility study, process design, scope test, R&D to production plan for Green Hydrogen, Grid-storage Battery system, Lithium-ion Batteries and Sustainable pyrolysis-free Recycling of Black mass from Lithium-ion battery waste. At Advanced Industry 4.0 Solutions, we are pioneering the next evolution of manufacturing and recycling technologies, with a specialized focus on lithium-ion batteries. With our cutting-edge Industry 4.0 solutions, we are reshaping the landscape of battery production and recycling.</Typography>
                </Grid>
                <Grid item xs={6}>
                    <img src={aboutUsSideImage} alt="aboutUsSideImage" style={{height: "300px", padding: "125px 0 0 125px"}}/>
                </Grid>
            </Grid>
        </Container>
        </Box>
        </>
    )
}