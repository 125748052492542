import React,{ useState } from "react";
import { Box, Typography, Grid, Button, Container, Avatar } from "@mui/material";
import {anupKumarRoi, saifulIslam,seikhNayeenFaisal } from "./assets.tsx";
import './style.css';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


export function OurTeam(){
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    return(
        <>
        <Box className="ourTeamBackground">
            <Container>
            <Typography className="ourTeamTitle">Our Team</Typography>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                    <Box className="cardOurTeam">
                    <Typography className="cardOurTeamDescription">
                    PhD in Chemical Engineering, USYD on Lithium-ion Batteries (LIBs).<br/>
                    10 years of research & industrial experiences on Battery Manufacturing.
                    Published over 30 research papers on LIBs.<br/>
                    Inventor of 04 US patents and one is under commercialization by Sicona Battery Technology for LIB manufacturing.  Industrial collaboration with CapXX, Deakin BatteryHub, Australian Institute of Innovative Materials, IRT Group, Ionic Supercapacitors and CSIRO.
                    </Typography>
                    <Grid container direction="row" style={{padding: "10px", marginTop: "-5%"}} justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                        <Avatar
                            alt="Dr. Shaikh Nayeem Faisal"
                            src={seikhNayeenFaisal}
                            sx={{ width: 70, height: 70 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className="cardTeamDesignation" style={{fontWeight: 600}}>
                            Dr. Shaikh Nayeem Faisal
                            </Typography>
                            <Typography className="cardTeamDesignation">
                            Sr. Research Fellow,<br/> Faculty of Engineering & IT,<br/> University of Sydney<br/>
                            Director, AI4.0S Pty Ltd.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
                    </Grid>
                    <Grid item>
                    <Box className="cardOurTeam">
                    <Typography className="cardOurTeamDescription">
                    PhD in Chemical Engineering, USYD on Lithium-ion Batteries (LIBs).<br/>
                    10 years of industrial experiences on Hydrogen & Battery Manufacturing.
                    Worked over 05 years as Process Engineer at Hazer Group Ltd., Australia
                    Inventors of several Patents and over 20 peer-reviewed research papers on hydrogen and energy storage systems.
                    <br/>
                    Specialist on Hydrogen and battery manufacturing & Recycling Plant Design

                    </Typography>
                    <Grid container direction="row" style={{padding: "10px", marginTop: "auto"}} justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                        <Avatar
                            alt="Dr. Shaikh Nayeem Faisal"
                            src={anupKumarRoi}
                            sx={{ width: 70, height: 70 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className="cardTeamDesignation" style={{fontWeight: 600}}>
                            Dr. Anup Kumar Roy
                            </Typography>
                            <Typography className="cardTeamDesignation">
                            Process Engineering Consultant,<br/> Sicona Battery Technology Pty Ltd.<br/>
                            Director, AI4.0S Pty Ltd.
                         

                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
                    </Grid>
                    <Grid item>
                        <Box className="cardOurTeam">
                    <Typography className="cardOurTeamDescription">
                    PhD in Chemical Engineering, Messy University, NZ on Advanced Composites.<br/>
                    20 years of industrial experiences on Composites, Hydrogen & Battery Manufacturing.
                    Worked as consultant over 15 years in Deep-tech companies around the world including Lockheed Martin, Rio Tinto, CSIRO, Hemptech, Pultron & Basalt Fibre Tech.
                    Over 50 research papers, 04 industrial linkage grants and couple of patents on advanced manufacturing.

                    </Typography>
                    <Grid container direction="row" style={{padding: "10px", marginTop: "5%"}} justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                        <Avatar
                            alt="Dr. Md. Saiful Islam"
                            src={saifulIslam}
                            sx={{ width: 70, height: 70 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className="cardTeamDesignation" style={{fontWeight: 600}}>
                            Dr. Md. Saiful Islam
                            </Typography>
                            <Typography className="cardTeamDesignation">
                            Adjunct Sr. Lecturer,<br/> Faculty of Mechanical & Manufacturing, <br/> University of New South Wales (UNSW)<br/>
                            Director, AI4.0S Pty Ltd
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </>
    )
}