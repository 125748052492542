import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {discoverBackground, backgroundOne} from "./assets.tsx";
import './style.css';


export function Manufacture(){
    return(
        <>
        <Box className="manufactureBackground">
        <Container>
            <Typography className="bannerTitleManufacture">Manufacturing Excellence</Typography>
<Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item style={{zIndex: 1}}>
                    <Box className="contentBoxManufacture">
            <Typography className="contentBoxTextManufacture">We pride ourselves on our state-of-the-art knowledge and expertise on manufacturing facilities, equipped with the latest advancements in automation, robotics, and quality control systems. This ensures that every lithium-ion battery produced by the guidance of AI4.0S meets the highest standards of safety, reliability, and performance. As a leader in Industry 4.0 solutions, we leverage the latest advancements in mechatronics, data analytics, and IoT to optimize every stage of the battery lifecycle. From raw material processing to manufacturing and end-of-life recycling, our comprehensive suite of solutions drives efficiency, quality, and sustainability.</Typography>
            </Box>
                    </Grid>
                    <Grid item>
                    <img src={backgroundOne} style={{width: "450px", height: "350px", objectFit: "fill"}}/>
                    </Grid>
                </Grid>
</Container>
</Box>
        
        </>
    )
}