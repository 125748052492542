import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Landingpage } from './pages/landingPage/src/LandingPage.tsx';
// import { Navbar } from './component/navbar/NavBar.tsx';
//import {Landingpage} from "../../addvanced-app/src/pages/landingPage/src/LandingPage";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Landingpage/>}/>
    </Routes>
  );
}

export default App;
