import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import './style.css';


export function OurMission(){
    return(
        <>
            <Box className="ourMissionBackground">
                <Container>
                    <Typography className="ourMissionTitle">Our Mission</Typography>
                    <Typography className="ourMissionBody">Our mission at AI4.0S is twofold: to revolutionize the way lithium-ion batteries are manufactured, ensuring efficiency, quality, and scalability, and to lead the charge in sustainable battery recycling practices, reducing waste and conserving valuable resources for future generations. We envision a future where the convergence of advanced technologies and sustainable practices revolutionizes the way lithium-ion batteries are manufactured and recycled. Our vision is to lead the industry towards a more efficient, resilient, and environmentally conscious future.</Typography>
                </Container>
            </Box>
        </>
    )
}