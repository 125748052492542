import React from "react";
import { OurMission } from "../../../component/ourMission/src/ourMission.tsx";
import { AboutUs } from "../../../component/aboutUs/src/aboutUs.tsx";
import { Footer } from "../../../component/footer/src/footer.tsx";
import { OurTeam } from "../../../component/ourTeam/src/ourTeam.tsx";
import { Commitment } from "../../../component/commitment/src/commitment.tsx";
import { Partnership } from "../../../component/partnership/src/partnership.tsx";
import { Innovation } from "../../../component/innovation/src/innovation.tsx";
import { Manufacture } from "../../../component/manufacturing/src/manufacturing.tsx";
import { Sustainability } from "../../../component/sustainability/src/sustainability.tsx";
import { JoinUs } from "../../../component/joinUs/src/joinUs.tsx";
import { Element } from 'react-scroll'
export function Landingpage(){
    return (
        <>
            <Element id='About Us' name='About Us'><AboutUs/></Element>      
            <Element id='Our Mission' name='Our Mission'><OurMission/></Element>   
            <Element id='Innovation' name='Innovation'><Innovation/></Element>   
            <Element id='Manufacturing' name='Manufacturing'><Manufacture/></Element>
            <Element id='Sustainability' name='Sustainability'><Sustainability/></Element>
            <Element id='Partnerships' name='Partnerships'><Partnership/></Element>
            <Element id='Commitment' name='Commitment'><Commitment/></Element>
            <Element id='Join Us' name='Join Us'><JoinUs/></Element>
            <Element id='Our Team' name='Our Team'><OurTeam/></Element>
            <Element id='Contact Us' name='Contact Us'><Footer/></Element>      
        </>
    )
}