import React from "react";
import { Box, Typography, Grid, Button,Container, Card, CardMedia, CardContent } from "@mui/material";
import {cardImage, partner1, partner2, partner3, partner4, partner5, partner6} from "./assets.tsx";
import './style.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

export function Partnership(){
    const data = ["Materials Science and Engineering","Chemical Engineering", "Mechanical Engineering", "Civil Engineering", "Sustainable Energy", "Advanced Manufacturing" ]
    const partners = [partner1, partner2, partner3, partner4, partner5, partner6]
    const partnersName= ["Sicona Battery Technology Ltd", "Hazer Group Ltd.","Novalith", "University of Sydney", "The University of Wollongong Australia","University of New South Wales"]
    return(
        <>
        <Container>
            
        <Typography className="bannerTitlePartnership">Partnerships and Collaboration</Typography>  
            {/* </Grid> */}
            <Box style={{margin: "50px 0"}}>
            <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            //ssr={true} // means to render carousel on server-side.
            infinite={true}
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={true}
            //customTransition="all .5"
            transitionDuration={500}
            partialVisbile={true}
            containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile"]}
            //deviceType={this.props.deviceType}
            //dotListClass="custom-dot-list-style"
            //itemClass="carousel-item-padding-40-px"
            >
                {data.map((item: string, index: number)=>{
                    return (
                        <div style={{margin: "20px 10px 30px 10px"}}>
                            {/* <Grid item>  */}
                                {/* <Grid container direction="column">
                                    <Grid item>
                                        <img src={partners[index]} height={150} width={120}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography>{partnersName[index]}</Typography>
                                    </Grid>
                                </Grid> */}
                                <Card sx={{ minWidth: 300 }}>
                                <CardMedia
                                    sx={{ height: 140, objectFit: 'fill' }}
                                    image={partners[index]}
                                    title="Card Image"
                                />
                                <CardContent>
                                    <Typography style={{fontSize: "16px", textAlign: "center", fontWeight: "600"}}>
                                    {partnersName[index]}
                                    </Typography>    
                                </CardContent>
                                </Card>
                            {/* </Grid> */}
                        </div>
                    )
                })}
                </Carousel>;
            </Box>
        </Container>
        
        </>
    )
}