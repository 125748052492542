import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {discoverBackground} from "./assets";
import { backgroundOne } from "./assets.tsx";
import './style.css';


export function Innovation(){
    return(
        <>
        <Box className="innovationBackground">
        <Container>
<Grid container direction="row" justifyContent="space-evenly"
  alignItems="center" style={{margin: "0px 0 50px 0", paddingTop: "120px"}}>
    <Grid item xs={5}>
    <Typography className="bannerTitleInnovation">Innovation and Expertise</Typography>
    <Typography className="bannerBodyInnovation">Led by a team of highly experts with PhDs in battery technology, materials science, and engineering, we leverage our collective expertise to develop ground-breaking solutions. Our commitment to innovation drives us to push the boundaries of what's possible, constantly seeking new methods and technologies to optimize clean energy storage manufacturing processes and enhance performance. Our pioneering innovation in pyrolysis-free black mass recovery from used lithium-ion batteries without emitting toxic gases and chemical wastes is going to disrupt the recycling industries towards more sustainable and environment friendly way.</Typography>
    </Grid>
    <Grid item xs={6}>
    <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item >
                    <Box className="contentBoxInnovation" style={{transform: "skewX(-10deg)"}}>
            
            </Box>
                    </Grid>
                    <Grid item >
                    <img src={backgroundOne} style={{width: "450px", height: "350px", transform: "skewX(-10deg)"}}/>
                    </Grid>
                </Grid>
    </Grid>
</Grid>
                
           
</Container>
</Box>
        
        </>
    )
}