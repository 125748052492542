import React from "react";
import { Box, Typography, Grid, TextField, InputAdornment } from "@mui/material";
import './style.css';
import {send, logo} from "./assets.tsx";
import { Link} from 'react-scroll'
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';
const menu = ["About Us", "Our Mission", "Innovation","Manufacturing","Sustainability","Partnerships","Commitment","Join Us", "Our Team", "Contact Us"]

export function Footer(){
    return(
        <Box className="backGround">
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} md={2}>
                    {/* <Typography className="footerTitle">Menu</Typography>
                    <Typography style={{padding: "4px 0"}}>Quick links</Typography>
                    <Typography style={{padding: "4px 0"}}>Contact info</Typography>
                    <Typography style={{padding: "4px 0"}}>News letters</Typography> */}
                    <img src={logo} alt="logo" style={{height: "90px", marginLeft: "-25%"}}/>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography className="footerTitle">Quick Links</Typography>
                    {menu.map((item:any, index: number)=>{
                        return(
                            <Link 
                            activeClass="active" 
                            to={item} 
                            spy={true} 
                            smooth={true} 
                            offset={50} 
                            duration={500} 
                            >
                            <Typography style={{cursor: "pointer", padding: "4px 0"}}>{item}</Typography></Link>
                        )
                    })}
                    {/* <Typography>FAQ</Typography>
                    <Typography>Consumer Area</Typography>
                    <Typography>Customer Care</Typography>
                    <Typography>Payment and Scheduals</Typography> */}
                </Grid>
                <Grid item md={2} xs={12}>
                    <Typography className="footerTitle">Contact info</Typography>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography>
                            AI4.0S Pty Ltd.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                            ABN/ ACN: 665974210
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                            <PlaceIcon style={{marginBottom: "-6px"}}/> Leumeah, Sydney, NSW 2560, Australia
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                            <EmailIcon style={{marginBottom: "-6px"}}/> ai4.0solutions@gmail.com
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                            <LanguageIcon style={{marginBottom: "-6px"}}/> www.ai4s.com.au
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                            <PhoneIcon style={{marginBottom: "-6px"}}/> +61451052603
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography className="footerTitle">News letters</Typography>
                    <TextField
                        variant="outlined"
                        style={{height: "30px"}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <img src={send} style={{height: "20px"}}/>
                            </InputAdornment>
                            ), disableUnderline: true, style: { textAlign: 'end', height: "40px" }
                        }}
                        />
                </Grid>
            </Grid>
        <Box className="divider"></Box>
        <Typography className="footerContent">Copyright &copy; 2023 All Right Reserved</Typography>
        </Box>
    )
}