import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {discoverBackground, sustainability} from "./assets.tsx";
import './style.css';


export function Sustainability(){
    return(
        <>
        {/* <Box className="bannerBackground"> */}
        <Container>
            <Grid container direction="row">
                <Grid item xs={6}>
                <Typography className="bannerTitleSustainability">Sustainability Focus</Typography>
            <Typography className="bannerBodySustainability">At AI4.0S, sustainability is at the core of everything we do. We understand the importance of minimizing the environmental footprint of hydrogen energy, battery production and recycling. That’s why we’re dedicated to developing innovative recycling technologies that allow us to recover and reuse valuable materials from end-of-life batteries, closing the loop and reducing reliance on finite resources. We are committed to fostering a circular economy for lithium-ion batteries. Through innovative recycling technologies and sustainable practices, we enable the recovery and reuse of valuable materials by reducing waste and conserving natural resources.</Typography>
                </Grid>
                <Grid item xs={6}>
                <img src={sustainability} alt="aboutUsSideImage" style={{height: "300px", padding: "100px 0 0 100px"}}/>
                </Grid>
            </Grid>
        </Container>
        {/* </Box> */}
        </>
    )
}