import { Box, Grid, Typography, TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import { logo } from "./src/asset.tsx";
import "./style.css";
import { Link} from 'react-scroll'
// const ScrollLink = Scroll.ScrollLink
export function Navbar() {
  const menu = ["About Us", "Our Mission", "Innovation","Manufacturing","Sustainability","Partnerships","Commitment","Join Us", "Our Team", "Contact Us"]
    return (
      <>
        <Grid
          container
          direction="row"  justifyContent="space-around"  alignItems="center"
          // style={{backgroundColor: "white"}}
        >
          <Grid item ><img src={logo} alt="logo" style={{height: "40px"}}/></Grid>
          <Grid item >
            <Grid container spacing={4}  direction="row"  justifyContent="center"  alignItems="center">
       
        {menu.map((item : string)=>{
                return(
                  <Grid item>
                  <Link 
                  activeClass="active" 
                  to={item} 
                  spy={true} 
                  smooth={true} 
                  offset={50} 
                  duration={500} 
                >
                  <Typography style={{fontSize: "14px", cursor: "pointer"}}>{item}</Typography>
                  </Link>
                  </Grid>
                )
              })}
      
            </Grid>
          </Grid>
          <Grid item >
              <TextField
              variant="standard"
              style={{backgroundColor: "aliceblue", padding: "7%"}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <SearchIcon sx={{color: "blue"}}/>
                  </InputAdornment>
                ), disableUnderline: true, style: { textAlign: 'end' }
              }}
              />
          </Grid>
        </Grid>
      </>
    );
  }