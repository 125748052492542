import React from "react";
import { Box, Typography, Grid, Button, Container } from "@mui/material";
import {commitment} from "./assets.tsx";
import './style.css';


export function Commitment(){
    return(
        <>
        <Box className="bannerBackgroundCommitment">
        <Container>
            <Grid container direction="row" spacing={4}>
                <Grid xs={6}>
                <Typography className="bannerTitleCommitment">Commitment to Excellence</Typography>
                <Typography className="bannerBodyCommitment">At AI4.0S, we are committed to excellence in everything we do. Whether it's developing next-generation battery materials, optimizing manufacturing processes, or implementing innovative recycling solutions, we are driven by a passion for innovation and a dedication to making a positive impact on the world. From our cutting-edge technologies to our commitment to sustainability, quality, and customer satisfaction, we strive to deliver superior value and impact to our clients and the communities we serve.</Typography>
                </Grid>
                <Grid xs={6} >
                    <img src={commitment} alt="commitment" style={{height: "300px", margin: "125px 0 0 125px", borderRadius: "10px"}}/>
                </Grid>
            </Grid>
            
</Container>
        </Box>
        </>
    )
}